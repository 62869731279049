import { Component, ComponentRef, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, last } from 'rxjs/operators';

import { CookieService } from './main/services/cookie/cookie.service';
import { LoaderService } from './core/components/loader/loader.service';
import { DataManagementService } from './core/services/data-management/data-management.service';
import { MultiLanguageMessageService } from './core/services/multi-language-message/multi-language-message.service';

import { Utility } from 'src/app/shared-main/classes/utility';
import { MainComponent } from './main/main/main.component';
import { DialogService } from './shared-preparation/services/dialog/dialog.service';
import { LoginService } from 'src/app/login/services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'web-app';
  isHideLoader: boolean = true;
  unsupportedBrowserText = '';

  private main: Element = null;

  /**
   * コンストラクタ
   *
   * @param changeDetector 変更検出
   * @param loaderService ローダーサービス
   * @param router ルータ情報
   * @param multiLanguageMessageService 多言語サービス
   * @param cookieService クッキーサービス
   * @param dialogService ダイアログサービス
   */
  /**
   * constructor
   *
   * @param changeDetector Change detector
   * @param loaderService Loader service
   * @param router Router information
   * @param multiLanguageMessageService Multi language service
   * @param cookieService Cookie service
   * @param dialogService Dialog service
   */
  constructor(
    private changeDetector: ChangeDetectorRef,
    private loaderService: LoaderService,
    private router: Router,
    private multiLanguageMessageService: MultiLanguageMessageService,
    private cookieService: CookieService,
    private dialogService: DialogService,
    private multiLanguage: MultiLanguageMessageService,
    private loginService: LoginService,
    private tabTitle: Title,
  ) {}

  /**
   * コンポーネントの初回表示時フック
   */
  /**
   * Angular life cycle hook (Initial display)
   */
  async ngOnInit() {
    this.subscribeLoadingState();
    await this.loginService.asyncGetLanguage(this.multiLanguage);
    // ブラウザのタブを多言語に対応
    // Support browser tabs in multiple languages
    this.tabTitle.setTitle(this.multiLanguageMessageService.dictionary('sidWebAppTabTitle'));
    // IEからのアクセス時はエラー画面表示
    // Error screen is displayed when accessing from IE
    if (DataManagementService.isIE()) {
      this.multiLanguageMessageService
        .getLanguageList()
        .pipe(last())
        .subscribe(() => {
          this.multiLanguageMessageService
            .changeLocale(navigator['userLanguage'])
            .pipe(last())
            .subscribe(() => {
              this.unsupportedBrowserText = this.multiLanguageMessageService.dictionary(
                'sidPleaseAccessOtherBrowser',
              );
            });
        });

      this.unsupportedBrowserText = this.multiLanguageMessageService.dictionary(
        'sidPleaseAccessOtherBrowser',
      );
      return;
    }

    this.subscribeRouterEvents();
    this.controlBrowserBack();
    // 多店舗管理アプリ対応： gtmは従来とは別の使い方をするのでコメントアウト
    // Multi-store management app support: GTM is used differently than before, so it is commented out.
    // if (this.cookieService.getCookieConsentState()) {
    //   this.cookieService.setGoogleTagManager();
    // }
  }

  /**
   * mainコンポーネントのElementの取得
   * @param {ComponentRef<any>} componentRef コンポーネントリファレンス
   */
  /**
   * Get Element of main component
   * @param {ComponentRef<any>} componentRef Component reference
   */
  getMainComponentElement(componentRef: ComponentRef<any>) {
    if (componentRef instanceof MainComponent) {
      const children = document.getElementsByTagName('app-main')[0].children;
      for (let i = 0; i < children.length; i += 1) {
        if (children[i].classList.contains('main')) {
          this.main = children[i];
        }
      }
    } else {
      this.main = null;
    }
  }

  /**
   * ローディング状態を購読する
   */
  /**
   * Subscribe to loading status
   */
  private subscribeLoadingState() {
    this.loaderService.loadingState().subscribe((isHide: boolean) => {
      requestAnimationFrame(() => {
        this.isHideLoader = isHide;
        this.changeDetector.detectChanges();
        if (!this.isHideLoader) {
          // ローディング表示する時にタブ可能な要素を無効にする
          // Disable tabbable elements while loading
          Utility.disableTabbableElements();
        } else {
          // ローディング表示しない時にタブ可能な要素を有効にする
          // Enable tabbable elements after loading stops
          Utility.enableTabbableElements();
        }
      });
    });
  }

  /**
   * ルーターのイベントを購読する
   */
  /**
   * Subscribe to router events
   */
  private subscribeRouterEvents() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.scrollToTop();
      this.addInvalidPageToHistory();
    });
  }

  /**
   * 画面遷移後にページ最上部へスクロールする
   */
  /**
   * Scroll to top of page after screen transition
   */
  private scrollToTop() {
    if (this.main != undefined) {
      this.main.scrollTo(0, 0);
    }
  }

  /**
   * ブラウザバック処理のため、無効なページを履歴に追加する
   */
  /**
   * Add invalid page to history for disable browser back and forward
   */
  private addInvalidPageToHistory() {
    history.pushState(null, null, null);
  }

  /**
   * ブラウザバックを制御する
   */
  /**
   * Control browser back
   */
  private controlBrowserBack() {
    window.addEventListener(
      'popstate',
      (e) => {
        this.addInvalidPageToHistory();
        if (this.dialogService.componentRef) {
          return;
        }
        this.dialogService.openDialog(
          null,
          this.multiLanguageMessageService.dictionary('sidBackForwardButton'),
          null,
          this.multiLanguageMessageService.dictionary('sidUCOk'),
          null,
        );
      },
      false,
    );
  }

  /**
   * コンポーネント破棄処理
   *
   */
  /**
   * Component destruction processing
   *
   */
  ngOnDestroy() {
    this.dialogService.closeDialog();
  }
}

import { Injector, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PopupModule } from '@progress/kendo-angular-popup';
import { IntlService, CldrIntlService } from '@progress/kendo-angular-intl';

import { SharedPreparationModule } from 'src/app/shared-preparation/shared-preparation.module';
import { RestClientPersonService } from './services/rest-client';
import { LocatorService } from './services/locator/locator.service';
import { LoaderComponent } from './components/loader/loader.component';
import { ToastComponent } from './components/toast/toast.component';
import { GoogleTagManagerCustomService } from '../google-analytics/services/google-tag-manager-custom.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    OverlayModule,
    PortalModule,
    ButtonsModule,
    LayoutModule,
    NotificationModule,
    PopupModule,
    SharedPreparationModule,
  ],
  declarations: [LoaderComponent, ToastComponent],
  exports: [LoaderComponent, ToastComponent],
  providers: [
    {
      provide: IntlService,
      useExisting: CldrIntlService,
    },
    RestClientPersonService,
    GoogleTagManagerCustomService,
  ],
})
export class CoreModule {
  /**
   * コンストラクタ
   *
   */
  /**
   * constructor
   *
   */
  constructor(private injector: Injector, @Optional() @SkipSelf() parentModule: CoreModule) {
    LocatorService.injector = this.injector;
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule Only');
    }
  }
}

/**
 * 多店舗アプリにおけるコンポーネント一覧
 */
/**
 * List of components in the multi-store application
 */
export enum MultiStoreComponent {
  // ログイン関連
  // Login related
  // Login = 1,
  // 共通機能
  // Common functions
  // Common = 2,
  // 故障マップ
  // FailureMap
  FailureMap = 3,
  // 運転状況
  // OperatingCondition
  OperatingCondition = 4,
  // 消費電力
  // PowerConsumption
  PowerConsumption = 5,
  // その他
  // Others
  // Others = 99,
}

/**
 * GAに送信する際のイベント名(小文字スネークケースで命名する必要がある)
 */
/**
 * The event name to send to GA (must be named in lowercase snake_case)
 */
export const MultiStoreComponentEventName: { [key in MultiStoreComponent]: string } = {
  // [MultiStoreComponent.Login]: 'login',
  // [MultiStoreComponent.Common]: 'common',
  [MultiStoreComponent.FailureMap]: 'failure_map',
  [MultiStoreComponent.OperatingCondition]: 'operating_condition',
  [MultiStoreComponent.PowerConsumption]: 'power_consumption',
  // [MultiStoreComponent.Others]: 'others',
};

import {
  BrowserModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
  HammerModule,
} from '@angular/platform-browser';
import { LOCALE_ID, NgModule, Injectable } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IntlService, CldrIntlService } from '@progress/kendo-angular-intl';
// ロケーションに合わせて、日付、金額を変更する場合、各国の設定値を読み込む必要あり
// When changing the date and amount according to the location,
// it is necessary to read the setting values of each country
import 'src/app/locales/kendo-locales';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';

import { LoaderService } from './core/components/loader/loader.service';
import { AppComponent } from './app.component';

import { AuthGuard } from './core/guards/auth.guard';
import { AppLoadGuard } from './core/guards/app-load.guard';
import { RestClientAuthService } from 'src/app/core/services/rest-client/rest-client-auth/rest-client-auth.service';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { DIRECTION_VERTICAL } from 'hammerjs';
import { LoginModule } from 'src/app/login/login.module';

import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { environment } from '../environments/environment';
@Injectable()
export class CustomHammerGestureConfig extends HammerGestureConfig {
  overrides = {
    pan: { direction: DIRECTION_VERTICAL, threshold: 2 },
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    DragDropModule,
    LoginModule,
    HammerModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsMeasurementId),
    NgxGoogleAnalyticsRouterModule,
    GoogleTagManagerModule.forRoot({
      id: environment.GoogleTagManagerId,
    }),
  ],
  providers: [
    CldrIntlService,
    LoaderService,
    RestClientAuthService,
    {
      provide: IntlService,
      useExisting: CldrIntlService,
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-GB',
    },
    AuthGuard,
    AppLoadGuard,
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerGestureConfig },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from '@angular/core';

import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from '../../../environments/environment';
import { MultiStoreComponent, MultiStoreComponentEventName } from '../enums/component.enum';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

/**
 * Google Tag Manager(GTM)のラッパー・拡張サービス
 */
/**
 * Wrapper and extension service for Google Tag Manager (GTM).
 */
@Injectable()
export class GoogleTagManagerCustomService {
  /**
   * コンストラクター関数
   * @param gtmService グーグルタグマネージャーサービス
   */
  /**
   * Constructor function
   * @param gtmService Google tag manager(GTM) service
   */
  constructor(
    private gtmService: GoogleTagManagerService,
    private gaService: GoogleAnalyticsService,
  ) {}

  /**
   * ユーザーIDをGTMとGAに設定する（ユーザーIDがGTMとGAに送信されるようになる）
   * @param userId ユーザーID
   */
  /**
   * Sets the user ID in GTM and GA (enables sending the user ID to GTM and GA)
   * @param userId The user ID
   */
  public setUserId(userId: string): void {
    // GTM IDまたはGA 観測ID のどちらかが設定されていない場合、処理を行わない
    // Skip processing if either GTM ID or GA Measurement ID is not configured
    if (!this.isSetGoogleTagManagerId() || !this.isSetGoogleAnalyticsMeasurementId()) {
      return;
    }

    this.gaService.gtag('set', { user_id: userId });
    this.gtmService.pushTag({
      event: 'multi_store_set_user_id',
      user_id: userId,
    });
  }

  /**
   * ユーザーIDをGTMとGAから削除する（ユーザーIDがGTMとGAに送信されないようになる）
   */
  /**
   * Removes the user ID from GTM and GA (prevents the user ID from being sent to GTM and GA)
   */
  public removeUserId(): void {
    // GTM IDまたはGA 観測ID のどちらかが設定されていない場合、処理を行わない
    // Skip processing if either GTM ID or GA Measurement ID is not configured
    if (!this.isSetGoogleTagManagerId() || !this.isSetGoogleAnalyticsMeasurementId()) {
      return;
    }

    this.gaService.gtag('set', { user_id: undefined });
    this.gtmService.pushTag({
      event: 'multi_store_remove_user_id',
      user_id: undefined,
    });
  }

  /**
   * GTMにイベントを送信する
   * @param component コンポーネント
   */
  /**
   * Sends an event to GTM
   * @param component The component
   */
  public pushEvent(component: MultiStoreComponent): void {
    // GTM IDまたはGA 観測ID のどちらかが設定されていない場合、処理を行わない
    // Skip processing if either GTM ID or GA Measurement ID is not configured
    if (!this.isSetGoogleTagManagerId() && !this.isSetGoogleAnalyticsMeasurementId()) {
      return;
    }

    // コンポーネントに対応したイベント名でイベントを送信する
    // Send an event using the event name corresponding to the component
    this.gtmService.pushTag({
      event: MultiStoreComponentEventName[component],
    });
  }

  /**
   * グーグルアナリティクス測定IDが設定されているか確認する
   * @returns 設定されている: true、 設定されていない: false
   */
  /**
   * Checks if the Google Analytics Measurement ID is configured.
   * @returns true if configured, false if not configured.
   */
  private isSetGoogleAnalyticsMeasurementId(): boolean {
    if (!environment.googleAnalyticsMeasurementId) return false;
    return true;
  }

  /**
   * グーグルタグマネージャーIDが設定されているか確認する
   * @returns 設定されている: true、 設定されていない: false
   */
  /**
   * Checks if the Google Tag Manager ID is configured.
   * @returns true if configured, false if not configured.
   */
  private isSetGoogleTagManagerId(): boolean {
    if (!environment.GoogleTagManagerId) return false;
    return true;
  }
}
